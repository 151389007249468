<template lang="html">
  <PowerBiEmbed
    :report-id="report.id"
    type="report"
    :config="config"
  >
    <template #default="{print, displayFullscreen}">
      <EmbedHeader
        :name="report.name"
        @print="print"
        @fullscreen="displayFullscreen"
        @back-to-workspace="$router.push({name: 'workspace'})"
        v-on="$route.query.fromDashboard ? {
          'back-to-dashboard': () => backToDashboard($route.query.fromDashboard)
        } : {}"
      />
    </template>
  </PowerBiEmbed>
</template>

<script>
import PowerBiEmbed from '@components/power-bi/PowerBiEmbed'

import EmbedHeader from './components/EmbedHeader'
// TODO back to dashboard if coming from a dashboard

export default {
  components: {
    PowerBiEmbed,
    EmbedHeader
  },
  props: {
    report: Object
  },
  computed: {
    config () {
      return {
        settings: {
          filterPaneEnabled: this.report.showFilters,
          navContentPaneEnabled: this.report.showNav
        },
        pageName: this.$route.query.pageName,
        theme: null// @Json.Serialize(Model.ColorTheme)
      }
    }
  },
  methods: {
    backToDashboard (dashboardId) {
      this.$router.push({name: 'dashboard', params: {...this.$route.params, reportId: dashboardId}})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
